import {
  AppConfig,
  baseAppConfigDevelopment,
  baseAppConfigProduction,
  baseAppConfigUAT,
} from '@core/shared/configuration'

const recaptchaSiteKey = '6Lft5YMcAAAAAEJ8k3lVq6dfUPHGav7rShpeka-1'

const appConfig: AppConfig = {
  development: {
    ...baseAppConfigDevelopment,
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
  },
  uat: {
    ...baseAppConfigUAT,
    api: {
      ...baseAppConfigUAT.api,
      clientId: '10661',
    },
    auth: {
      ...baseAppConfigUAT.auth,
      clientId: '10661.LatusHealthPWA',
      clientSecret: 'e23f769d-6479-7889-cabb-5c633054bd8a',
    },
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
    clientURL: 'https://latushealth-test.azurewebsites.net',
  },
  production: {
    ...baseAppConfigProduction,
    api: {
      ...baseAppConfigProduction.api,
      clientId: '834',
    },
    auth: {
      ...baseAppConfigProduction.auth,
      clientId: '834.Latus Health PWA',
      clientSecret: '5775353f-9b97-ee86-80c3-047659af4e8e',
    },
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
  },
}

export default appConfig
