import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

import { TermsAndCondsTemplate } from '@core/pages/TermsAndConditions/TermsAndCondsTemplate'
import { CreateAccountPage } from '@core/pages/Register/CreateAccountPage'
import { Login } from '@core/pages/Login'
import { VerifyEmailPage } from '@core/pages/VerifyEmail'
import { JoinConsultationPage } from '@core/pages/JoinConsultation'
import { ForgotPasswordPage } from '@core/pages/ForgotPasswordPage'
import { ResetPasswordPage } from '@core/pages/ResetPassword/ResetPasswordPage'
import { LandingPage } from './pages/Home/LandingPage'
import { AppFooter } from '@core/components/app/AppFooter'
import { AccountCreatedPage } from '@core/pages/Register/AccountCreatedPage'
import { ServicePage } from '@core/pages/SelectServices/ServicePage'

const PublicApp = () => {
  return (
    <>
      <Switch>
        <Route path={ScreenRoutes.TERMS_AND_CONDS}>
          <TermsAndCondsTemplate />
        </Route>
        <Route path={ScreenRoutes.CREATE_ACCOUNT}>
          <CreateAccountPage />
        </Route>
        <Route path={ScreenRoutes.SELECT_SERVICE}>
          <ServicePage />
        </Route>
        <Route path={ScreenRoutes.LOGIN}>
          <Login />
        </Route>
        <Route path={ScreenRoutes.VERIFY_EMAIL}>
          <VerifyEmailPage />
        </Route>
        <Route path={ScreenRoutes.ACCOUNT_CREATED}>
          <AccountCreatedPage />
        </Route>
        <Route path={ScreenRoutes.JOIN_CONSULTATION}>
          <JoinConsultationPage />
        </Route>
        <Route path={ScreenRoutes.FORGOT_PASSWORD}>
          <ForgotPasswordPage />
        </Route>
        <Route path={ScreenRoutes.RESET_PASSWORD}>
          <ResetPasswordPage />
        </Route>
        <Route>
          <LandingPage />
        </Route>
      </Switch>
      <AppFooter />
    </>
  )
}

export default PublicApp
