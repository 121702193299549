import { FeatureFlagOptions } from '@core/components/app/features/features'
import { ClientVariables } from '@core/components/app/features/variables'
import { makeCountriesList, countryCodes } from '@core/shared/data/countryCodes'

const features: FeatureFlagOptions = {
  articles: true,
  servicesNearYou: true,
  dependants: true,
  messageGp: false,
  clientIdentifierField: false,
  clientIdentifierPin: false,
  clientIdentifierToSAPI: false,
  confirmEmailAddress: false,
  gpPharmacyMap: true,
  hasSymptomsField: true,
  symptomAssessment: false,
  isPharmacyRequired: false,
  hasStandardAuth: true,
  hasStandardBookingEntry: true,
  bookings: {
    services: {
      value: {
        GeneralPractitioner: {
          isEnabled: true,
        },
      },
    },
  },
}

const clientVariables: ClientVariables = {
  clientVariables: {
    phoneNumber: '03453 190 477',
    phoneInputPlaceholder: 'e.g. +4401234567890',
    clientIdentifierLabel: 'Client code',
    countryList: makeCountriesList(countryCodes, ['GB', 'IE']),
    baseCountryCode: 'GB',
    clientName: 'Online GP',
  },
  dashboardVariables: {
    logoAlt: 'Health Hero',
    logoLargeWidth: 56,
    logoSmallWidth: 70,
    specialtiesLayout: 'GpConsultation',
    hasBackground: true,
  },
  landingVariables: {
    backgroundStyles: {
      borderRadius: '0 0 80px 0',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    },
    landingSlider: {
      numSlides: 2,
      slide2Content: [
        'Unlimited access to experienced GPs',
        'Private prescription, private fit note, or a private open referral letter',
        '24/7 Customer Service Team',
      ],
    },
  },
}

export const getFeatures = () => features

export const getClientVariables = () => clientVariables
