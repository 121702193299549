import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

import { HomePage } from './pages/Home/HomePage'
import { TermsAndCondsTemplate } from '@core/pages/TermsAndConditions/TermsAndCondsTemplate'
import { CreateAccountPage } from '@core/pages/Register/CreateAccountPage'
import { Login } from '@core/pages/Login'
import { WaysToBook } from '@core/pages/WaysToBook/WaysToBook'
import { BookConsultationPage } from '@core/pages/Consultations/Book/BookConsultationPage'
import { ProfilePage } from '@core/pages/Profile/Profile'
import { EditProfilePage } from '@core/pages/Profile/EditProfilePage'
import { AboutPage } from './pages/About/AboutPage'
import { MyContacts } from '@core/pages/MyContacts/MyContactsPage'
import { AddNewContactPage } from '@core/pages/MyContacts/AddNewContactPage'
import { EditContactPage } from '@core/pages/MyContacts/EditContactPage'
import { EmergencyDisclaimer } from '@core/pages/EmergencyDisclaimer'
import { EditGpConsultationPage } from '@core/pages/Consultations/Edit/EditUpcomingBookingPage'
import { ViewPastConsultation } from '@core/pages/Consultations/Past/ViewPastConsultationItem'
import { VerifyEmailPage } from '@core/pages/VerifyEmail'
import { JoinConsultationPage } from '@core/pages/JoinConsultation'
import { ArticlesPage } from './pages/Articles'
import { ArticlePage } from '@core/pages/Articles/Article'
import { HealthServicesPage } from './pages/Articles/HealthServices'
import { HealthWellbeingPage } from './pages/Articles/HealthWellbeing'
import { MedicalInformationPage } from './pages/Articles/MedicalInformation'
import { AppFooter } from '@core/components/app/AppFooter'
import { RequireFeature } from '@core/components/app/features/features'
import { ServicePage } from '@core/pages/SelectServices/ServicePage'
import { ChangePasswordPage } from '@core/pages/ChangePassword/ChangePassword'

const ProtectedApp = () => {
  return (
    <>
      <Switch>
        <Route path={ScreenRoutes.TERMS_AND_CONDS}>
          <TermsAndCondsTemplate />
        </Route>
        <Route path={ScreenRoutes.CREATE_ACCOUNT}>
          <CreateAccountPage />
        </Route>
        <Route path={ScreenRoutes.LOGIN}>
          <Login />
        </Route>
        <Route path={ScreenRoutes.DASHBOARD}>
          <HomePage />
        </Route>
        <Route path={ScreenRoutes.CHOOSE_CONSULTATION}>
          <WaysToBook />
        </Route>
        <Route path={ScreenRoutes.BOOK_CONSULTATION}>
          <BookConsultationPage />
        </Route>
        <Route path={ScreenRoutes.SELECT_SERVICE}>
          <ServicePage />
        </Route>
        <Route path={ScreenRoutes.PROFILE}>
          <ProfilePage />
        </Route>
        <Route path={ScreenRoutes.ABOUT}>
          <AboutPage />
        </Route>
        <Route path={ScreenRoutes.EDIT_PROFILE}>
          <EditProfilePage />
        </Route>
        <Route
          path={ScreenRoutes.DEPENDENTS}
          render={() => (
            <RequireFeature feature={['dependants']}>
              <MyContacts />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.ADD_NEW_CONTACT}
          render={() => (
            <RequireFeature feature={['dependants']}>
              <AddNewContactPage />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.EDIT_CONTACT}
          render={() => (
            <RequireFeature feature={['dependants']}>
              <EditContactPage />
            </RequireFeature>
          )}
        />
        <Route path={ScreenRoutes.CHANGE_PASSWORD}>
          <ChangePasswordPage />
        </Route>
        <Route path={ScreenRoutes.EMERGENCY_DISCLAIMER}>
          <EmergencyDisclaimer />
        </Route>
        <Route path={ScreenRoutes.EDIT_GP_CONSULTATION}>
          <EditGpConsultationPage />
        </Route>
        <Route path={ScreenRoutes.VIEW_LAST_CONSULTATION}>
          <ViewPastConsultation />
        </Route>
        <Route path={ScreenRoutes.VERIFY_EMAIL}>
          <VerifyEmailPage />
        </Route>
        <Route path={ScreenRoutes.JOIN_CONSULTATION}>
          <JoinConsultationPage />
        </Route>

        <Route
          path={ScreenRoutes.ARTICLES}
          render={() => (
            <RequireFeature feature={['articles']}>
              <ArticlesPage />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.HEALTH_WELLBEING}
          render={() => (
            <RequireFeature feature={['articles']}>
              <HealthWellbeingPage />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.MEDICAL_INFORMATION_RESULTS}
          render={() => (
            <RequireFeature feature={['articles']}>
              <MedicalInformationPage />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.ARTICLE}
          render={() => (
            <RequireFeature feature={['articles']}>
              <ArticlePage />
            </RequireFeature>
          )}
        />
        <Route
          path={ScreenRoutes.HEALTH_SERVICES}
          render={() => (
            <RequireFeature feature={['articles', 'servicesNearYou']}>
              <HealthServicesPage />
            </RequireFeature>
          )}
        />
        <Route exact path={ScreenRoutes.HOME}>
          <HomePage />
        </Route>
        <Route>
          <Redirect to={ScreenRoutes.HOME} />
        </Route>
      </Switch>
      <AppFooter />
    </>
  )
}

export default ProtectedApp
